@use 'styles/abstracts' as *;

.product-cocktail {
    position: relative;
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px 10vh 10px;
    overflow: hidden;

    @include respond-to("large") {
        padding: 0 10px 17vh 10px;
    }

    &__content {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include respond-to("large") {
            flex-direction: row;
            align-items: flex-start;
        }

        > * {
            flex: 1 1 0;
            z-index: 1;
        }
    }

    &__recipe {
        
    }

    &__tips {
        p{
            margin: 0 5%;
        }
    }
    &__recipe,
    &__tips {
        margin: 5px;

        @include respond-to("large") {
            margin: 140px 5px 5px;
        }
    }

    &__photo {
        position: relative;
        order: -1;
        z-index: 0;
        padding: 0 60px;

        @include flexcenter;

        img {
            // min-width: 40vw;
            transform: scale(0.7);
        
            @include respond-to("large") {
                transform: scale(1.2);
            }

        }

        @include respond-to("large") {
            // position: absolute;
            order: 0;
        }
    }

    &__caption {
        position: absolute;
        text-align: center;
        color: color(general, white100);

        @include respond-to("large") {
            // white-space: nowrap;
        }
    }

    h5 {
        margin: 0.5em 0;

        @include respond-to("large") {
            margin: 1.25em 0;
        }
    }
}