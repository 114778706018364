@use 'styles/abstracts' as *;

.faq-entry-wrapper{
    padding: 10px;
    margin-bottom: 0px;

    @include respond-to("huge") {
        padding: 20px;
        margin-bottom: 10px;
    }
}

.faq-question{
    margin: 10px;
    width: 80%;
}

.faq-entry-header {
    position: relative;
    cursor: pointer;
}

.faq-divider{
    background-color: color(general, light-black);
    height: 2px;
    margin-bottom: 1em;
}

.faq-answer-container{
    margin-left: 16px;
    margin-right: 0;
}

.faq-answer-paragraph{
    white-space: pre-wrap;
    font-size: 13px;
    line-height: 1.5em;

    @include respond-to("huge") {
        width: 80%;
        margin-right: 16px;
    }
}

.faq-expand-arrow-container {
    right: 14px;
    position: absolute;
    top: 2px;

    width: 32.39px;
    height: 23.76px;
}

.faq-expand-arrow {
    width: 32.39px;
    height: 23.76px;
}