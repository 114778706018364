@use 'styles/abstracts' as *;

.product-page {
    display: block;
    position: relative;
    background-color: color(general, black);
    background-image: url('/assets/images/noise-bkg.png');
    @include repeatbackground;

    overflow-x: hidden;

    width: 100%;
    @include minScreenHeight;

    .product-list {
        position: relative;
        display: grid;
        gap: 0 0% ;
        justify-content: center;
        align-content: center;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

        width: 1400px;
        max-width: 100%;
        margin: 0 auto;
        min-height: 100vh;
        padding: 120px 20px 20px;
    }

    .product {
        position: relative;
        z-index: 0;
        max-width: 80%;
        @include flexcenter;
        margin: 0 auto;
        cursor: pointer;
        padding-bottom: 8%;

        figure {
            img {
                min-width: 50%;
                margin: 0 auto;
            }
        }

        &__lable {
            position: absolute;
            color: color(general, white100);
            top: 20%;
            opacity: 1;
            text-transform: uppercase;

            img {
                height: 1em;
                max-width: none;
            }
        }
        
        .transform {
            transform: scale(0.9);
        }
        @include respond-to("large") {
            .transform {
                transform-origin: 50% 80%;
                transform: scale(0);
            }

            &:hover,
            &:active,
            &.active {
                .transform {
                    transform: scale(0.9);
                }
            }
        }
    }
}