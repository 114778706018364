@use 'styles/abstracts' as *;

.loader {
    position: fixed;
    z-index: 1000;

    background-color: color(grey, 500);

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 90px;

    &__bicycle {
        width: 100px;
        max-width: 100%;
        margin: 2em auto;
        
        @include respond-to("large") {
            width: 140px;
        }
    }

    &__text {

    }
}