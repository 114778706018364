@use 'styles/abstracts' as *;

.product-detail-page {
    position: relative;
    width: 100%;
    @include minScreenHeight;
    padding-bottom: 1px;

    background: linear-gradient(0deg, #ffffff, #ffffff), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;
    @include repeatbackground;

    .back-to-products {
        @include flexcenter;

        margin: 0 0 120px;
        gap: 1em;
    }

    .see-more {
        font-size: 13px;

        @include flexcenter;
        gap: 0.5em;
        flex-wrap: nowrap;

        svg {
            width: 1.5em;
        }
        path {
            fill: currentColor;
        }
    }
}
