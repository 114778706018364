@use 'styles/abstracts' as *;

.about-us-section1-wrapper {
    min-height: 100vh;
    @include flexcenter;
    flex-direction: column;

    .deco-star{
        transform: scale(0.5);
    
        @include respond-to("large") {
            transform: scale(0.7);
        }
    }

    h3{
        padding: 0 5%;
    }
}

.about-us-section1-page {
    width: 100%;
    // min-height: 100vh;
    @include flexcenter;
    flex-direction: column;
}

.about-us-section1-title-container {
    position: relative;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    align-self: center;
    text-align: center;
    color: color(darkgold, 500);
    padding: 8px 1em;

    br {
        display: none;
        @include respond-to("large") {
            display: inline;
        }
    }
    h5 {
        margin: .5em 0;
    }

    h6{
        margin: 0 5%;

        @include respond-to("large") {
            margin: 0 12%;
        }
    }

    h3{
        letter-spacing: -1px;
    }
}

.about-us-section1-p{
    white-space: pre-line;
    font-size: 17px;
    line-height: 26px;
    margin: 0 2em;
    @include respond-to("large") {
        margin: 0 7em;
    }  
}

.about-us-section1-star {
    margin: 0 auto;
    display: block;
    width: 36px;
}

.about-us-section1-title {
    font-family: "Cormorant", "MSungHK";
    margin: 8px auto;
    text-align: center;
    font-size: 40px;
    line-height: 1.5em;
    letter-spacing: -2px;
    padding: 0 5%;

    @include respond-to("large") {
        margin: 48px auto;
        font-size: 72px;
        line-height: 1.2em;
        letter-spacing: -2px;
    }

    span{
        display: block;
    }
}

.about-us-section1-black {
    background-color: color(general, light-black);
}
