@use 'styles/abstracts' as *;

.story-section {
    position: relative;
    z-index: 0;

    p {
        margin: 1em 5% 0 3%;
    }
    blockquote {
        border-left: 2px color(darkgold, 500) solid;
        padding-left: 1em;
        color: color(darkgold, 500);
    }

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;

        width: 720px;
        max-width: 100%;
    }

    .when-met {
        z-index: 0;
        gap: 20px;
        position: relative;
        overflow: hidden;
        color: color(darkgold, 500);
        background-color: color(general, light-black);
        text-align: center;

        .deco-star {
            transform: scale(0.5);

            @include respond-to("large") {
                transform: scale(0.7);
            }
        }

        .about-us-story-p{
            white-space: pre-line;
            font-size: 17px;
            line-height: 26px;
            margin: 0 2em;
            @include respond-to("large") {
                margin: 0 7em;
            }  
        }

        h6{
            margin: 0 5%;
    
            @include respond-to("large") {
                margin: 0 12%;
            }
        }

        h3{
            padding-bottom: 2vh;
        }
    }
    .godhand {
        z-index: -1;
        position: absolute;
        width: 40%;
        max-width: 80%;
        opacity: 0.5;

        &--right {
            width: 65%;
            top: 12%;
            right: 0;
            transform: rotate(-19.71deg);
            @include respond-to("large") {
                width: 30%;
                top: unset;
                transform: translateY(-40%) rotate(-19.71deg);
            }
            
            img {
                width: 100%;
                transform: translateX(25%);
                margin: 0 0 0 auto;
                @include respond-to("large") {
                    transform: translateX(18%);
                }
            }
        }
        &--left {
            width: 60%;
            top: 58%;
            left: 0;
            transform: rotate(-4.34deg);

            @include respond-to("large") {
                width: 30%;
                top: unset;
                transform: translateY(15%) rotate(-4.34deg);
            }

            img {
                width: 100%;
                transform: translateX(-25%);
                @include respond-to("large") {
                    transform: translateX(-10%);
                }
            }
        }
    }
    
    .lifestyle {
        display: flex;
        color: color(darkgold, 500);
        background-color: color(sand, 75);
        gap: 2em;
        padding: 3vh 3vh 3vh;
        height: auto;
        min-height: 100vh;
        z-index: 0;

        @include respond-to("large") {
            gap: 3em;
            padding: 3vh 10vh 3vh;
        }

        &__content {
            position: relative;
            min-height: 100%;
            width: 1400px;
            max-width: 100%;
            margin: 0 auto;
            gap: 2em;
        }

        &__quote {
            width: 500px;
            max-width: 100%;

            @include respond-to("large") {
                align-self: flex-start;
            }
        }
        &__believe {
            gap: 1em;
            width: 960px;
            max-width: 100%;
        }

        &__background {
            pointer-events: none;
            z-index: -1;
            @include sameSizeWithParent;

            figure {
                height: 90%;
                transform: translateX(5%);
                @include respond-to("large") {
                    padding: 50px;
                    transform: translateX(0);
                }

                > img {
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        
        .title-m{
            margin: 0 2% 0 0;

            @include respond-to("large") {
                margin-right: 15%;
            }
        }

        .deco-star {
            transform: scale(0.5);

            @include respond-to("large") {
                transform: scale(0.7);
            }
        }

        .lifestyle-blockquote{
            width: 80%;
            font-size: 17px;
            line-height: 26px;

            @include respond-to("large") {
                width: 60%;
            }
        }
    }
    .born {
        display: flex;
        background-color: color(darkgold, 500);
        padding: 0;
        height: auto;
        min-height: 100vh;
        z-index: 0;
        padding: 0 1em;

        @include respond-to("large") {
            gap: 3em;
        }  

        &__content {
            position: relative;
            min-height: 100%;
            width: 1400px;
            max-width: 100%;
            margin: 0 auto;
            gap: 1em;
            justify-content: flex-start;
            margin-top: 20%;

            @include respond-to("large") {
                justify-content: center;
                margin-top: 0;
            }  
        }

        &__quote {
            width: 600px;
            max-width: 100%;
            margin-top: 10vh;
            margin-left: 0%;

            @include respond-to("large") {
                margin-left: 40%;
                margin-top: 0;
            }

            blockquote {
                color: color(general, black);
                border-left: 2px color(general, black) solid;
            }
        }
        &__slogan {
            position: relative;
            color: color(general, black);
            padding-left: 2em;
            margin-left: 0%;
            gap: 0.5em;

            @include respond-to("large") {
                margin-left: 40%;
            }
            
            h2 {
                font-weight: bold;
                font-size: 32px;
                
                @include respond-to("large") {
                    font-size: 56px;
                }
            }
            .deco-star {
                position: absolute;
                left: -26px;
            }
        }

        &__background {
            pointer-events: none;
            z-index: -1;
            @include sameSizeWithParent;

            white-space: nowrap;
            overflow: hidden;

            figure {
                background-color: #b28147;
                display: inline-block;
                margin-right: -12%;
                z-index: -1;
                vertical-align: bottom;

                &.bottle1 {
                    width: 20%;
                }
                &.bottle2 { 
                    width: 100%;
                    @include respond-to("large") {
                        width: 80%;
                    }
                }

                img {mix-blend-mode: multiply;}
            }
        }
            
        .deco-star {
            transform: scale(0.5);

            @include respond-to("large") {
                transform: scale(0.5);
            }
        }

        h4{
            font-weight: 500;
        }
    }
}