@use 'styles/abstracts' as *;

.input-field {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0.5em;

    &__input {
        flex: 1;
        position: relative;
        padding: 0.5em;
        color: color("general", "white100");
        background: none;
        border: 2px transparent solid;
        overflow: hidden;
        vertical-align: middle;
        z-index: 1;
        outline: 0;
        border: 1px color("general", "white100") solid;
    }
    &__input:disabled {
        border-color: color(general, white50);
    }

    &__success,
    &__error {
        position: absolute;
        left: 0;
        bottom: -1.65em;
        font-size: 0.8em;
    }
    &__error {
        color: color(roselle, dark-red);
    }
    &__success {
        color: color(darkgold, 500);
    }
}
