@use 'styles/abstracts' as *;

.about-us-section3-wrapper {
    @include minScreenHeight;
    background-color: color(general, light-black);
    overflow: hidden;
    padding-top: 120px;
}

.about-us-section3-liquor-journey-intro-wrapper{

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;

    width: 75vw;
    height: 75vw;

    max-width: 575px;
    max-height: 575px;

    margin-top: 104px;
    margin-bottom: 50px;

    @include respond-to("huge") {
        width: 575px;
        height: 575px;
        margin-top: 67px;
    }
}

.about-us-section3-liquor-journey-intro-image{

    width: 75%;

    max-width: 437px;
    max-height: 437px;

    @include respond-to("huge") {
        width: 437px;
        height: 437px;
    }
}


.about-us-section3-liquor-journey-rotating-text{

    position: absolute;

    width: 100%;

    max-width: 575px;
    max-height: 575px;

    top: 0;
    left: 0;

    @include respond-to("huge") {
        width: 575px;
        height: 575px;
    }

    transition: 0.25s ease-out;
}