@use 'styles/abstracts' as *;

.center-timeline {
    position: relative;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    padding: 40px 0 160px 0;
    z-index: 0;
    
    &::after {
        // background-color: color(darkgold, 500);
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        top: 0;
        left: 50%;
        z-index: -1;
    }

    .scrolldown-line {
        position: absolute;
        width: 1.5px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
}

.timeline-node-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column-reverse;
    text-align: center;

    margin: 130px 30px 0;
    background-color: color(general, light-black);
    
    @include respond-to("large") {
        background: none;
        flex-direction: row;
        align-items: flex-start;
        text-align: left;

        &.left-node {
            flex-direction: row-reverse;
        }
    }

    > * {
        order: 0;
    }
}

.timeline-node-text-wrapper {
    width: 400px;
    max-width: 90%;
    margin-top: 11px;
}

.timeline-node-star {
    width: 36px;
    height: 44px;
    position: relative;
    z-index: 2;

    .deco-star {    
        max-width: 100%;
        max-height: 100%;
    }
    
    order: 1;
    @include respond-to("large") {
        order: 0;
    }
}

.timeline-node-title {
    font-size: 28px;
    line-height: 1;
    font-family: "Vast Shadow", "MSungHK";
    color: color(darkgold, 500);
    position: relative;
    z-index: 2;

    @include respond-to("large") {
        font-size: 32px;
    }
}

.timeline-node-desc {
    width: 356px;
    max-width: 100%;
    font-size: 14px;
    font-family: "Myanmar MN", "MSungHK";
    margin-top: 30px;
    color: color(general, white100);
    position: relative;
    z-index: 2;
    line-height: 23px;

    @include respond-to("large") {
        font-size: 16px;
        line-height: 23px;
    }
}

.timeline-node-image {
    margin-top: 40px;
    width: 400px;
    max-width: 90%;
    position: relative;
    z-index: 2;
}
@include respond-to("large") {
    .timeline-node-image {
        margin-top: 10px;
        margin-right: 112px;
    }
    .timeline-node-text-wrapper {
        margin-left: 112px;
    }
    .left-node {
        text-align: right;
        
        .timeline-node-desc {
            margin-left: auto;
        }

        .timeline-node-image {
            margin-left: 112px;
            margin-right: 0;
        }
        .timeline-node-text-wrapper {
            margin-right: 112px;
            margin-left: 0;
        }
    }
}

.timeline-top-right-flower
{
    z-index: -1;
    position: absolute;
    right: -200px;
    top: 0;

    @include respond-to("large") {
        right: 0;
    }
}

.timeline-top-left-flower
{
    z-index: -1;
    position: absolute;
    left: -200px;
    top: -100px;

    @include respond-to("large") {
        left: 0;
    }
}

.timeline-top-left-flower2
{
    z-index: -1;
    position: absolute;
    left: -200px;
    top: 600px;

    @include respond-to("large") {
        top: 250px;
        left: 0;
    }
}

.timeline-bottom-flower
{
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 55%;

    @include respond-to("large") {
        left: 25%;
    }
}

.timeline-bottom-right-flower
{
    z-index: -1;
    position: absolute;
    bottom: 800px;
    right: -200px;

    @include respond-to("large") {
        bottom: 250px;
        right: 0;
    }
}