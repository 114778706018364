.flex-layout {
    display: flex;
}
.flex-start {
    justify-content: flex-start;
    align-items: flex-start;
}
.flex-center {
    justify-content: center;
    align-items: center;
}
.flex-end {
    justify-content: flex-end;
    align-items: flex-end;
}
.flex-direction-row { flex-direction: row; }
.flex-direction-row-reverse { flex-direction: row-reverse; }
.flex-direction-column { flex-direction: column; }
.flex-direction-column-reverse { flex-direction: column-reverse; }
.justify-content-center {
    justify-content: center;
}
.justify-content-start {
    justify-content: flex-start;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-space-around {
    justify-content: space-around;
}
.justify-content-space-between {
    justify-content: space-between;
}
.justify-content-space-evenly {
    justify-content: space-evenly;
}
.align-items-center {
    align-items: center;
}
.align-items-start {
    align-items: flex-start;
}
.align-items-end {
    align-items: flex-end;
}
.flex-1 {
    flex: 1;
}
