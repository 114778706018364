@use 'styles/abstracts' as *;

.menu-list-item
{
    display: flex;
    align-items: center;
    transform: translateX(-22px);
    cursor: pointer;

    @include respond-to("huge") {
        transform: translateX(-41.25px);
    }
}

.menu-list-item-pointer-container{

    @include respond-to("huge") {
        height: 46.21px;
    }
}

.menu-list-item-pointer{

    @include respond-to("huge") {
        width: 82.51px;
        height: 46.21px;
    }
}

.menu-list-item-text
{
    font-size: 30px;
    line-height: 1em;
    margin: .25em 0;

    @include respond-to("huge") {
        font-size: 60px;
    }

    &.light {
        color: color(general, white100);
    }

    &.dark {
        color: color(general, dark);
    }
}