@use 'styles/abstracts' as *;

.illustrations {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    // overflow: hidden;

    > * {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: -2%;

        @include respond-to("large") {
            top: 0%;
        }
    }
}

.godhands {

    position: sticky;
    top:0;

    > * {
        position: absolute;
        width: 400px;
        max-width: 50%;

        &:first-child {
            top: 40%;
            left: -3%;
        }
        &:last-child {
            top: 20%;
            right: -5%;
        }
    }

    > img {
        width: 100%;
    }
}

.sideFlower {

    position: sticky;
    top:0;

    > * {
        position: absolute;
        width: 400px;
        max-width: 50%;

        &:first-child {
            top: 34%;
            left: -2%;
        }
    }
    
    
    .transform {
        transform: rotate(131deg);
    }
}



.homeplant1 {
    position: absolute;
    top: -25%;
    left: 11%;
    width: 65%;
}

.homeplat-hand-flower {
    @include sameSizeWithParent;
    opacity: 0.65;
}
.homeplant4,
.homeplant21,
.homeplant22 {
    position: absolute;
    opacity: 0;
}

.homeplant21 {
    width: 17%;
    top: -14%;
    left: 31%;

    animation: 2s linear 0s 1 normal forwards running homeplant_fadeinout;
    transform-box: fill-box;
    transform-origin: 50% 50%
}

.homeplant22 {
    top: -17%;
    left: 26%;
    width: 30%;
    
    animation: 2s linear 1s 1 normal forwards running homeplant_fadeinout;
    transform-box: fill-box;
    transform-origin: 50% 50%
}

.homeplant4 {
    top: -22%;
    left: 26%;
    width: 30%;

    animation: 2s linear 1.5s 1 normal forwards running homeplant_fadein;
    transform-box: fill-box;
    transform-origin: 50% 50%
}

@keyframes homeplant_fadein {
	0% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes homeplant_fadeinout {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}