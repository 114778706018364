@use 'styles/abstracts' as *;

.back-to-top {
    position: fixed;
    right: 10px;
    bottom: 70px;
    z-index: 99;

    @include respond-to("huge") {
        right: 50px;
    }

    &__button {
        color: color(general, black);
        
        -webkit-transition: opacity 0.3s, color 0.3s;
        -webkit-transition: -webkit-opacity 0.3s, -webkit-color 0.3s;
        transition: opacity 0.3s, color 0.3s;
        cursor: pointer;
    
        font-size: 0.75em;
        line-height: 1.15em;

        @include respond-to("huge") {
            font-size: 1em;
        }
    
        &:hover,
        &:focus {
            opacity: 1;
        }

        svg {
            margin-bottom: 0.5em;
    
            path {
                fill: currentColor;
            }
        }
    }
}
