@use 'styles/abstracts' as *;

$background: rgba(250, 225, 202, 1);
$premiumBackground: rgba(223, 202, 178, 1);
$normalColor: color(roselle, dark-red);
$premiumColor: color(darkgold, 500);

.promotion-page {
    --mainColor: #{$normalColor};
    --bgColor: #{$background};
    width: 100%;
    position: relative;
    min-height: 100vh;
    background: var(--bgColor);

    @include respond-to("large") {
        width: 100%;
        height: 100vh;
        min-height: 950px;
        overflow-y: hidden;
    }

    &.premium {
        --mainColor: #{$premiumColor};
        --bgColor: #{$premiumBackground};
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-to("large") {
            flex-direction: row;
            height: 100%;
            width: auto;
            flex-wrap: nowrap;

            > * {
                flex: 1 0 auto;
            }
        }
    }

    &__header {
        position: relative;

        @include respond-to("large") {
            position: absolute;
            width: 100vw;
            left: 0;
            top: 0;
            z-index: 1;
        }

        .illustration {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            
            @include respond-to("large") {
                align-items: flex-start;
            }

            img {
                width: 250px;
                max-width: 50%;
                transform: scale(-1, 1);
                
                @include respond-to("large") {
                    transform: scale(1, 1);
                }
            }

            .language {
                display: flex;
                padding: 20px 30px;

                > div {
                    flex: 0;
                    cursor: pointer;
                    padding: 0.25em 0;
                    margin: 0 0.5em;

                    &.active {
                        color: var(--mainColor);
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__instruction {
        z-index: 0;
        position: relative;
        padding: 20px 35px;
        width: 100%;
        
        @include respond-to("large") {
            width: 60vw;
            padding-top: 240px;
            padding-left: 80px;
        }

        h1,h2,h3,h4,h5,h6 {
            color: var(--mainColor);
        }
        .subtitle {
            width: 80%;

            @include respond-to("large") {
                font-size: 21px;
                line-height: 24px;
            }
        }
        .title {
            font-size: 42px;
            line-height: 40px;
            margin-top: 0.25em;
            margin-bottom: 0.5em;
            
            @include respond-to("large") {
                font-size: 60px;
                margin-bottom: 0.25em;
            }
        }
        .instruction-content {
            position: relative;

            @include respond-to("large") {
                padding-bottom: 130px;
                padding-right: 160px;
            }
        }
        .deco {
            width: 330px;
            max-width: 50%;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: -1;
            opacity: 0.5;
        }

        .scroll-down {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--mainColor);
            margin: 1em;

            path {
                fill: currentColor;
            }

            @include respond-to("large") {
                display: none;
            }
        }

        .scroll-right {
            display: none;
            position: relative;
            color: var(--mainColor);
            margin-bottom: 2em;
            justify-content: flex-end;
            align-items: center;

            path {
                fill: currentColor;
            }

            &::before {
                content: '';
                position: absolute;
                width: 100vw;
                height: 1px;
                right: 44px;
                background-color: currentColor;
            }

            @include respond-to("large") {
                display: flex;
            }
        }
    }

    &__form {
        max-width: 130vw;
        // max-width: 2000px;
    }

    &__ticket {
        .ticket-section1 { 
            position: relative;
            z-index: 0;
            padding: 0 0px;
        }

        .instruction {
            color: color(general, white100);
            padding: 0 20px;
        }
        .click-here {
            display: none;
            text-align: center;
            margin-top: 20px;

            .button {
                width: 300px;
                max-width: 100%;
                padding: 0.25em 1em;

                > * {
                    padding: 0 0.25em;
                    vertical-align: middle;
                }

                svg {
                    width: auto;
                    height: 1em;

                    path {
                        fill: currentColor;
                    }
                }
            }

            @include respond-to("large") {
                display: block;
            }
        }
    }

    .star-list {
        position: relative;
        list-style: none;
        padding: 0 8%;

        @include respond-to("large") {
            padding: 0;
        }

        li {
            position: relative;
            margin-bottom: 1em;
            padding-left: 1em;
            font-size: 20px;
            line-height: 26px;

            .list-star {
                position: absolute;
                left: -0.5em;
                top: 0;
                width: 1em;
                height: auto;

                path {
                    fill: currentColor;
                }
            }
        }
    }

    .ticket-section2 {
        position: relative;
        z-index: 0;
        padding: 0 20px;
        width: 100%;

        // @include respond-to("large") {
        //     padding: 0 2%;
        // }

        .dear {
            font-size: 28px;
            line-height: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;
            width: 100%;
            font-weight: bold;

            > input {
                font-size: 17px;
                flex: 1;
                vertical-align: bottom;
                @include respond-to("large") {
                    font-size: 20px;
                }
            }

            @include respond-to("large") {
                span {
                    font-size: 64px;
                }
            }
        }

        .post-card-last-row {
            margin-top: 1em;
            width: 100%;
        }

        .from-date {
            font-size: 20px;
            line-height: 1.5;
            white-space: nowrap;

            &__name {
                display: inline-block;
                max-width: 100%;
                width: 50px;
            }

            &__month {
                text-transform: uppercase;
            }
        }

        .post-card-content {
            position: relative;
            width: 100%;
            padding-bottom: 0px;
            margin: 1em 0;

            textarea {
                font-size: 17px;
                // line-height: 30px;

                @include respond-to("large") {
                    font-size: 20px;
                }

                &:not(:invalid) {
                    // font-family: "Cormorant", "Times Sans Serif", "MSungHK", sans-serif;
                    // font-weight: bold;
                    // font-style: italic;
                }
            }
            textarea:focus + .word-count {
                opacity: 0.8;
            }
            .word-count {
                position: absolute;
                left: 5px;
                bottom: -1.25em;
                font-size: 17px;
                line-height: 1.5;
                opacity: 0;

                transition: opacity 0.3s;
            }
        }

        .logo {
            width: 140px;
            max-width: 40%;

            svg {
                vertical-align: bottom;
                max-width: 100%;
                height: auto;
            }
        }

        .deco {
            flex-shrink: 0;
            width: 330px;
            max-width: 50%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            opacity: 0.1;

            @include respond-to("large") {
                display: none;
            }
        }
    }

    .ticket-section3 {
        color: color(general, white100);

        @include respond-to("large") {
            padding-right: 0px;
        }

        > *:not(.input-tips) {
            margin: 1.5em 0;
        }

        textarea,
        input {
            display: block;
            width: 100%;
            max-width: 100%;
            height: 2em;
            font-size: 17px;
            line-height: 28px;
            color: color(general, white100);
            border-color: currentColor;
            font-weight: bold;

            @include respond-to("large") {
                font-weight: normal;
            }
            
            &::placeholder {
                color: color(general, white100);
            }
        }

        .button {
            font-size: 21px;
            padding: 0.25em 5%;

            background-color: var(--mainColor);
            transition:  background-color 0.3s;

            &:hover,
            &:focus {
                background-color: color(general, white100);
                color: color(general, black);
            }

            svg {
                height: 0.8em;
                width: auto;
                vertical-align: middle;

                path {
                    fill: currentColor;
                }
            }
        }
    }

    hr {
        border-color: color(general, black);
        border-bottom: 1px;
    }

    input,
    textarea {
        background: none;
        border: 0;
        padding: 5px;
        outline: 0;
        transition: outline 0.3s;

        &::placeholder {
            color: #622D1C;
        }
    }
    input {
        width: 140px;
        max-width: 50%;
        display: inline-block;
        border-bottom: 1px color(general, black) solid;

        @include respond-to("large") {
            width: 320px;
        }
    }
    textarea {
        width: 100%;
        min-height: 20em;
        resize: none;

        &:focus {
            outline: 1px currentColor solid;
        }
    }
    .input-field,
    textarea {
        font-size: .75em;
    }
}

.premium {
    textarea::placeholder {
        color: rgba(98, 63, 28, 1);
    }
}

.desktop-ticket {

}

.mobile-ticket {
    z-index: 0;
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @include respond-to("large") {
        flex-direction: row;

        > :nth-child(odd) {
            flex: 1;
        }

        > :nth-child(even) {
            flex: 2;
        }
    }

    &__page {
        position: relative;
        overflow: hidden;

        &.light {
            background: var(--mainColor);
        }
        &.dark {
            background: color(general, black);
        }
    }
    &__page:not(:first-child) &__content {
        border-top: 0;
        
        @include respond-to("large") {
            border-top: 6px color(general, black) solid;
            border-left: 0;
        }
    }
    &__page:not(:last-child) &__content {
        border-bottom: 0;
        
        @include respond-to("large") {
            border-bottom: 6px color(general, black) solid;
            border-right: 0;
        }
    }
    &__content {
        padding: 32px 20px;
        border: 6px color(general, black) solid;

        @include flexcenter;
        flex-direction: column;

        @include respond-to("large") {
            height: 100%;
            padding: 30px 40px;
        }
    }

    &__corners {
        pointer-events: none;
        @include sameSizeWithParent;
        z-index: 1;

        > div {
            position: absolute;
            width: 56px;
            height: 56px;
            background-color: var(--bgColor);
            border: 6px color(general, black) solid;
            border-radius: 28px;
            border-color: color(general, black) transparent transparent transparent;

            &:nth-child(1) {
                transform: rotate(135deg);
                top: -28px;
                left: -28px;
            }
            &:nth-child(2) {
                transform: rotate(225deg);
                top: -28px;
                right: -28px;
            }
            &:nth-child(3) {
                transform: rotate(315deg);
                bottom: -28px;
                right: -28px;
            }
            &:nth-child(4) {
                transform: rotate(45deg);
                bottom: -28px;
                left: -28px;
            }
        }
    }
    &__page:last-child &__corners {
        > div:nth-child(3) { border-color: var(--mainColor) transparent transparent transparent; }
        > div:nth-child(4) { border-color: var(--mainColor) transparent transparent transparent; }
        
        @include respond-to("large") {
            > div:nth-child(2) { border-color: var(--mainColor) transparent transparent transparent; }
            > div:nth-child(4) { border-color: color(general, black) transparent transparent transparent; }
        }
    }

    &__edge {
        pointer-events: none;
        z-index: 2;
        position: absolute;
        left: 28px;
        top: 6px;
        width: calc(100% - 56px);
        height: 6px;
        margin: 0 auto;
        color: var(--bgColor);

        @include respond-to("large") {
            width: 6px;
            height: calc(100% - 56px);
            border-bottom: 0;
            color: var(--bgColor);
            left: 6px;
            top: 28px;
        }

        &--light {
            color: var(--bgColor);
        }
        &--dark {
            color: color(general, black);
        }
    }
    &__page:first-child &__edge {
        color: var(--bgColor);
        top: -3px;

        @include respond-to("large") {
            top: 28px;
            left: -3px;
        }
    }
    &__page:not(:last-child) &__edge:last-child { display: none; }
    &__page:last-child &__edge:last-child {
        color: var(--bgColor);
        top: auto;
        bottom: -3px;

        @include respond-to("large") {
            right: -3px;
            left: auto;
        }
    }

    &__tail {
        position: absolute;
        text-align: center;
        font-weight: bold;
        font-size: 13px;
        width: 100%;
        bottom: 0;
        height: 28px;
        background-color: var(--mainColor);

        
        @include respond-to("large") {
            font-size: 18px;
            width: 28px;
            height: 100%;
            right: 0;
            writing-mode: vertical-lr;
            text-orientation: mixed;
        }
    }
}

.departure {
    z-index: 0;
    position: relative;
    background: color(general, black);
    color: color(general, white100);
    overflow: hidden;

    &__wrapper {
        z-index: -1;
        position: relative;
        border: 3px var(--mainColor) solid;
        padding: 6px;
    }

    &__content {
        padding: 30px 20px;
        border: 1px var(--mainColor) solid;
    }

    &__corners {
        pointer-events: none;
        @include sameSizeWithParent;
        z-index: 1;

        > div {
            position: absolute;
            width: 50px;
            height: 50px;
            background-color: color(general, black);
            border: 1px var(--mainColor) solid;
            border-radius: 50%;
            border-color: var(--mainColor) transparent transparent transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                content: "";
                position: absolute;
                width: 120%;
                height: 120%;
                background-color: color(general, black);
                border: 3px var(--mainColor) solid;
                border-radius: 50%;
                border-color: var(--mainColor) transparent transparent transparent;
                transform: translate(0, 10px) scaleX(115%);
            }

            &:nth-child(1) {
                transform: rotate(135deg);
                top: -19px;
                left: -19px;
            }
            &:nth-child(2) {
                transform: rotate(225deg);
                top: -19px;
                right: -19px;
            }
            &:nth-child(3) {
                transform: rotate(315deg);
                bottom: -19px;
                right: -19px;
            }
            &:nth-child(4) {
                transform: rotate(45deg);
                bottom: -19px;
                left: -19px;
            }
        }
    }

    &__legend {
        position: absolute;
        color: var(--mainColor);
        background: color(general, black);
        left: 50%;
        top: 0px;
        padding: 0 0.5em;
        font-size: 20px;
        line-height: 1.25;
        transform: translate(-50%, 0);
    }

    &__year {
        width: 100%;
        font-size: 30px;
        padding: 5px;
        border-bottom: 1px currentColor solid;
        text-align: center;
    }
    &__month-grid {
        width: 100%;
        height: auto;
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;

        > div {
            padding: 0.25em;
            border-right: 1px color(general, white100) solid;
            border-bottom: 1px color(general, white100) solid;

            &.active {
                color: var(--mainColor);
            }

            &:nth-child(3n) {
                border-right: 0;
            }
            &:nth-last-child(-n+3)
            {
                border-bottom: 0;
            }
        }
    }

    &__onewayticket {
        text-align: center;
        margin: 0.5em 0;
        font-size: 20px;
        text-transform: uppercase;
        color: var(--mainColor);
    }
}