@use 'styles/abstracts' as *;

.deco-star {
    fill: color(darkgold, 500);

    &--black {
        fill: color(general, black);
    }
    &--pink {
        fill: rgba(221, 162, 150, 1);
    }
}