@use 'styles/abstracts' as *;

.product-plants {
    @include sameSizeWithParent;
    @include flexcenter;
    
    .transform {
        @include sameSizeWithParent;
        -webkit-transition: transform 0.5s;
        -webkit-transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        z-index: -1;
    }

    img {
        position: absolute;
    }

    &--1 {
        bottom: 45%;
        left: -4%;
        width: 70%;
    }
    &--2 {
        bottom: 42%;
        right: 4%;
        width: 52%;
    }
    &--3 {
        bottom: 12%;
        right: -13%;
        width: 50%;
    }
    &--4 {
        bottom: 16%;
        left: -23%;
        width: 59%;
    }
    &--5 {
        bottom: 42%;
        left: -9%;
        width: 66%;
    }
    &--6 {
        bottom: 12%;
        right: -30%;
        width: 88%;
    }
    &--7 {
        bottom: 16%;
        left: -12%;
        width: 46%;
    }
}