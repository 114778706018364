@use 'styles/abstracts' as *;

.faq-page{
    padding: 10px;
    padding-top: 100px;
    width: 100%;
    @include minScreenHeight;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;

    @include respond-to("huge") {
        padding-top: 200px;
    }
}

.faq-group{
    width: 95%;
    @include minScreenHeight;
    margin: 0 auto;

    @include respond-to("huge") {
        width: 76%;
    }
}