.contact-us {
    position: relative;
    width: 100%;
    min-height: 100vh;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;

    &__page {
        width: 100%;
        padding-top: 120px;
        min-height: 100vh;
        text-align: center;

        p {
            margin-bottom: 1em;
        }
    }

    &__map {
        width: 1200px;
        max-width: 100%;
        padding: 2% 30px;

        iframe {
            width: 100%;
            height: 520px;
        }
    }
}