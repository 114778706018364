@use 'styles/abstracts' as *;

.menu-background
{
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, color(darkgold, 500), color(darkgold, 500)), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;
}

.menu-container
{
    position: absolute;
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0;

    @include respond-to("huge") {
        height: 100%;
        top: 0;
        bottom: 0;
    }
}

.mobile-social-media-section
{
    display: flex;
    justify-content: space-between;
    width: 121.33px;
    margin-top: 55px;

    #facebook {
        width: 35.33px;
        height: 35.33px;
    }

    #instagram {
        width: 35.33px;
        height: 35.33px;
    }
}