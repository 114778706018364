@use 'styles/abstracts' as *;

.introduction {
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 0;
    
    background-color: color(general, light-black);    
    transition: background-color 0.2s;

    &--light-theme {
        background-color: color(sand, 75);
    }
    
    &__line {
        @include flexcenter;

        width: 75%;
        height: 100%;

        svg.path {
            display: block;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            overflow: visible;
            width: 100%;
            height: auto;
            margin: 0 auto;
            font-size: 98%;

            .line {
                width: 100%;
                height: 100%;
                stroke-width: 1;
                
                stroke: white;
            }

            text {
                font-size: 5rem;
                fill: color(darkgold, 500);
                transform: translate(0px, -10px);

                @include respond-to("medium")
                {
                    font-size: 2rem;
                }

                @include respond-to("large")
                {
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__star {
        position: fixed;
        top: 50%;
        left: 50%;
        @include flexcenter;

        svg {
            position: absolute;
        }
    }

    &__galaxy {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__subtitle {
        position: absolute;
        white-space: auto;
        color: color(darkgold, 500);
        width: 80vw;
        text-align: center;

        @include respond-to("large") {
            br {
                display: none;
            }
        }
    }

    &__overlay-cover {
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        @include flexcenter;
        color: color(darkgold, 500);
        z-index: -1;

        .circle {
            @include flexcenter;
            position: absolute;
            font-size: 50px;
            width: 1em;
            height: 1em;
            transition: color 0.3s;
            color: color(darkgold, 500);

            &.active {
                color: color(sand, 75);
            }
            
            .blob,
            .collection {
                position:absolute;
                width: 1em;
                height: 1em;
                background: currentColor;
                border-radius: 50%;
            }
        }
    }

    .call-to-scroll {
        position: absolute;
        left: 50vw;
        bottom: 0;
        margin: 20px 0;
        transform: translateX(-50%);
        opacity: 0.5;

        color: color(general, white100);
    }
}