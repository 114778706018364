@use 'styles/abstracts' as *;

.blog-post-page{
    width: 100%;
    @include minScreenHeight;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;
}

.blog-post-thumbnail-container{
    position: relative;
    height: 720px;

    @include respond-to("huge") {
        height: 752px;
    }
}

.blog-post-thumbnail{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.blog-post-thumbnail-mask{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

    background: linear-gradient(180deg, #5A574F 0%, #D3CAB2 16.67%, #231F20 100%);
    mix-blend-mode: multiply;
}

.blog-post-thumbnail-info{
    position: absolute;
    bottom: 65px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.blog-post-info-title {

    width: 75%;
    margin: 0 auto;

    // font-family: "Vast Shadow", "MSungHK";
    color: color(general, white100);
    margin-bottom: 40px;

    font-size: 28px;
    line-height: 40px;

    @include respond-to("huge") {
        font-size: 40px;
        line-height: 56px;
    }
}

.blog-post-info-pointer {
    width: 65px;
    height: auto;
    transform: rotate(90deg);

    > path{
        fill: color(general, white100);
    }
}

.blog-post-info-date-tags-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: color(general, white100);
    margin-bottom: 16px;  
}

.blog-post-info-date {
    font-size: 16px;
    font-weight: bold;
    color: color(general, white100);
}

.blog-post-info-divider {
    margin-left: 24px;
    margin-right: 24px;
    height: 28px;
    width: 1px;
    background-color: color(general, white100);
}

.blog-post-info-tags {
    color: color(general, white100);
}

.blog-post-body{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    padding-bottom: 52px;

    @include respond-to("huge") {
        min-width: 832px;
        width: 832px;
    }
}

.blog-post-body-content-wrapper{
    > img{
        width: 100%;
        margin: 12px 0;
    }
}

.blog-post-body-first-p{
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 24px;
}

.blog-post-footer{
    background-color: color(sand, 50);
    padding: 48px 0;
}

.blog-post-footer-title{
    // font-family: "Vast Shadow";
    font-size: 28px;
    line-height: 1.5emm;

    text-align: center;
    color: color(darkgold, 500);
    margin: 24px 0;

    @include respond-to("huge") {
        font-size: 40px;
        line-height: 56px;
    }
}

.blog-post-footer-related-post{
    margin: 0 auto;
    margin-top: 24px;
    display: flex;
    justify-content: center;

    flex-direction: column;

    @include respond-to("huge") {
        flex-direction: row;
        width: 95%;
    }
}