@use 'styles/abstracts' as *;

.homepage {
    color: color(general, white100);

    .hideoverflow {
        overflow: hidden;
    }
}

.sticky-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh;
    z-index: z("below");

    &__background {
        position: sticky;
        top: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(0deg, rgba(52, 34, 13, 0.8), rgba(52, 34, 13, 0.8)), radial-gradient(46.68% 46.68% at 50% 47.41%, rgba(64, 59, 44, 0) 35.94%, #403B2C 100%), url('/assets/images/100cabinet-origin.jpg');
        background-blend-mode: multiply, multiply, normal;
        @include coverbackground;
    }
}
