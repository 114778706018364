@use 'styles/abstracts' as *;

.liner-component {

    position: relative;
    color: color(darkgold, 500);

    &__line {
        background-color: currentColor;
        position: absolute;
        width: 1px;
        height: 100%;
        max-height: 100%;

        transition: height 0.1s;
    }
}