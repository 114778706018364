@use 'styles/abstracts' as *;

.nav-bar{
    z-index: 101;
    position: fixed;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    min-height: 60px;

    @include respond-to("huge") {
        margin-top: 47px;
    }
}

.shop-here-section {
    
    display: none;

    @include respond-to("huge") {
        cursor: pointer;
        margin-left: 48px;
        display: flex;
        align-items: center;
    }
}

.menu-section {

    display: none;

    @include respond-to("huge") {
        cursor: pointer;
        margin-left: 36px;
        display: flex;
        align-items: center;
    }
}

.social-media-section,
.close-menu-section {

    display: none;
    cursor: pointer;

    @include respond-to("huge") {
        display: flex;
        justify-content: space-between;
        margin-right: 52px;
        gap: 16px;
    }
}

.translation-section {
    
    display: none;

    @include respond-to("huge") {
        cursor: pointer;
        // margin-left: 36px;
        display: flex;
        align-items: center;
    }
}

.nav-section-wrapper {
    align-items: center;

    &.flex-left {
        
        display: none;

        @include respond-to("huge") {
            position: absolute;
            top: 25%;
            left: 0;
            display: flex;
            width: 300px;
            justify-content: flex-start;
        }
    }

    &.flex-right {

        display: none;

        @include respond-to("huge") {
            position: absolute;
            top: 25%;
            right: 0;
            display: flex;
            width: 350px;
            justify-content: flex-end;
        }
    }
}

.nav-bar-text {
    font-size: 20px;
    transition: 0.25s;

    &.left-text {
        margin-right: 21px;
    }

    &.right-text {
        margin-left: 21px;
    }

    &.small-text {
        font-size: 15px;
    }

    &.light {
        color: color(general, black);

        :hover > & {
            color: color(general, black);
        }
    }

    &.dark {
        color: color(general, white75);

        :hover > & {
            color: color(general, white100);
        }
    }
}

.nav-bar-icon {

    &.point
    {
        width: 41.26px;
        height: 23.1px;

        &.light {
            :hover > & > path {
                transition: 0.25s;
                fill: color(general, black);
            }
        }

        &.dark {
            :hover > & > path {
                transition: 0.25s;
                fill: color(general, white100);
            }
        }
    }

    &.light {
        > path{
            transition: 0.25s;
            fill: color(general, black);
        }

        &:hover > path {
            fill: color(general, black);
        }
    }

    &.dark {
        > path{
            transition: 0.25s;
            fill: color(general, white75);
        }

        &:hover > path {
            fill: color(general, white100);
        }
    }
}

.mobile-menu-section {
    position: absolute;
    display: block;
    top: 15%;
    cursor: pointer;

    &--right {
        right: 29.33px;
    }
    &--left {
        left: 29.33px;
    }

    @include respond-to("huge") {
        display: none !important;
    }
}

.nav-bar-mobile-icon {

    width: 21.33px;

    &.light {
        > path{
        fill: color(general, black);
        }

        :hover > & > path {
            fill: color(general, black);
        }
    }

    &.dark {
        > path{
        fill: color(general, white75);
        }

        :hover > & > path {
            fill: color(general, white100);
        }
    }
}

.nav-bar-logo {

    width: 50vw;

    max-width: 225.79px;
    max-height: 60px;

    @include respond-to("huge") {
        width: 307.89px;
        height: 80px;

        max-width: 307.89px;
        max-height: 80px;
    }
}

#facebook {
    &:hover{
        cursor: pointer;
    }
}

#instagram {
    &:hover{
        cursor: pointer;
    }
}