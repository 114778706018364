.horizontal-scroller {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100vh;

    &__sticky {
        position: sticky;
        top: 0;    
        width: 100%;
        height: 100vh;
    }

    &__scroller-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    &__viewport {
        position: relative;
        display: flex;
        flex-direction: row;
    }
    &__page {
        position: relative;
        width: 100%;
        height: 100vh;
    }
}