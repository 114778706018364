@use 'styles/abstracts' as *;

.fullpage {
    position: relative;
    width: 100vw;
    max-width:100%;
    @include minScreenHeight;
    
    &__page {
        position: relative;
        width: 100vw;
        max-width:100%;
        height: 100vh;
    }

    .fullpage_navigator {
        display: block;
        position: fixed;
        right: 2em;
        top: 50%;
        transform: translateY(-50%);

        a {
            display: block;
            position: relative;
        }
    }
}
    
.page-container {
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
}
