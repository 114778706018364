@use 'styles/abstracts' as *;

.parallax-grass {
	.overlay-container
	{
		@include sameSizeWithParent;
		padding-bottom: 60px;
	}
    .overlay {
		@include sameSizeWithParent;
		
		&--dark {
			z-index: 1;
			background: rgb(0,0,0);
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4990371148459384) 15%, rgba(0,0,0,0.10407913165266103) 25%, rgba(255,255,255,0) 100%);
		}
    }

    &.atTop {
        transform: scaleY(-1);
    }

    figure {
        flex-shrink: 0;
    }

    .left,
    .right,
    .special,
    .particles {
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: 50px;
        gap: 3%;
        transform: scale(0.55);
        
        @include respond-to("large") {
            gap: 15%;
            transform: scale(1);
        }
    }
    .left {
        justify-content: flex-start;
    }
    .right {
        justify-content: flex-end;
    }
    .back {
        
        .left {
            left: -25%;
            transform-origin: bottom left;
        }
        .right {
            right: 0;
            transform-origin: bottom right;
        }
        @include respond-to("large") {
            .left {
                left: 5%;
            }
            .right {
                right: 0;
            }
        }
    }
    .front {
        
        .left {
            left: 0;
            transform-origin: bottom left;
        }
        .right {
            right: 10%;
            // gap: 6%;
            transform-origin: bottom right;
        }
        @include respond-to("large") {
            .left {
                left: 9%;
            }
            .right {
                right: 10%;
                gap: 50%;
            }
        }
        .special {
            transform-origin: bottom right;
            right: 0;
            margin-bottom: 110px;

            &__animation {
                width: 100px;
                height: 100px;
                position: absolute;
                top: -78px;
                left: 26px;

                @include flexcenter;

                .homeplant18,
                .homeplant19,
                .homeplant20 {
                    position: absolute;
                    opacity: 0;
                }
            }
        }

        .particles {
            transform-origin: bottom left;
            left: 80px;
            margin-bottom: 110px;
            
            @include respond-to("large") {
                left: calc(9% + 160px);
                margin-bottom: 220px;
            }

            img:not(:first-child) {
                position: absolute;
            }
        }
    }
}
.homeplant16 {
    width: 120px;
	animation: 3s linear 0s infinite normal forwards running homeplant16;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes homeplant16 {
	0% {
		opacity: 0;
	}
	33.33% {
		opacity: 1;
	}
	53.33% {
		opacity: 0;
	}
}
.homeplant17 {
    width: 170px;
    max-width: none;
	animation: 4s linear 0s infinite normal forwards running homeplant17;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes homeplant17 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	80% {
		opacity: 0;
	}
	23.33% {
		opacity: 0;
	}
}

.homeplant18 {
	animation: 3s linear 0s normal forwards running homeplant18;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes homeplant18 {
	0% {
		opacity: 1;
	}
	33.33% {
		opacity: 0;
	}
}

.homeplant19 {
	animation: 3s linear 0s normal forwards running homeplant19;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes homeplant19 {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	80% {
		opacity: 0;
	}
	16.67% {
		opacity: 0;
	}
	36.67% {
		opacity: 1;
	}
}

.homeplant20 {
	animation: 3s linear 0s normal forwards running homeplant20;
	transform-box: fill-box;
	transform-origin: 50% 50%;
    
    left: -12px;
}
@keyframes homeplant20 {
	0% {
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.homeplant5 {
	animation: 3s linear 0s infinite normal forwards running homeplant5;
	transform-box: fill-box;
	transform-origin: 50% 100%
}
@keyframes homeplant5 {
	0% {
		transform: rotate(0deg);
	}
	33.33% {
		transform: rotate(4.79deg);
	}
	66.67% {
		transform: rotate(-4.79deg);
	}
}
.homeplant8 {
	animation: 3s linear 0s infinite normal forwards running homeplant8;
	transform-box: fill-box;
	transform-origin: 50% 100%
}
@keyframes homeplant8 {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-15.9deg);
	}
	26.67% {
		transform: rotate(15.9deg);
	}
}
.homeplant9 {
	animation: 3s linear 0s infinite normal forwards running homeplant9;
	transform-box: fill-box;
	transform-origin: 50% 100%
}
@keyframes homeplant9 {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-4deg);
	}
	26.67% {
		transform: rotate(4deg);
	}
}
.homeplant12 {
	animation: 3s linear 0s infinite normal forwards running homeplant12;
	transform-box: fill-box;
	transform-origin: 50% 100%
}
@keyframes homeplant12 {
	0% {
		transform: rotate(0deg);
	}
	33.33% {
		transform: rotate(9.39deg);
	}
	66.67% {
		transform: rotate(0deg);
	}
}