@use 'styles/abstracts' as *;

.homesection3 {

    &.fullpage__page {
        height: auto !important;
        min-height: 100vh;
    }

    .content {
        position: relative;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
        background-blend-mode: multiply, normal;
        min-height: 100%;
    }

    .bottle {
        position: sticky;
        position: -webkit-sticky;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        top: 0;
        overflow: hidden;

        .transform {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            top: 0;
            -webkit-transition: transform 1s;
            -webkit-transition: -webkit-transform 1s;
            transition:  transform 1s;
        }

        img {
            position: absolute;
            width: 50vh;
            max-width: 100vh;
        }

        .medicines {
            
        }
        .liquidDrop {
            transform-origin: top center;
        }
        .glass {
            width: 25vh;
            transform: translate(0, 35vh);
        }
        .action {
            position: absolute;
            color: color(general, black);
            -webkit-transition: color .3s, filter 0.3s;
            -webkit-transition: color .3s, -webkit-filter 0.3s;
            transition: color .3s, filter 0.3s;
            width: 100%;
            text-align: center;
            padding: 0 60px;

            svg path { fill: currentColor; }

            &:hover,
            &:focus {
                color: color(general, light-black);
                filter: drop-shadow(0px 6px 8px rgba(24, 39, 75, 0.12));
            }
        }
    }
}