.fade-in-section {
    --delay: 0s;
    opacity: 0;
    transform: translateY(5vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
    transition-delay: var(--delay);
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}