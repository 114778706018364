@use '../../styles/abstracts' as *;

.anchor-group{
    z-index: 1;
    position: fixed;
    right: 0;
    width: 48px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.anchor-node{
    cursor: pointer;
    margin: 10px auto;
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    font-size: 8px;

    @include respond-to("large") {
        font-size: 12px;
    }
}