@use 'styles/abstracts' as *;

.blog-post-preview-wrapper{

    display: flex;
    flex-direction: column;
    margin: 20px;
    // height: 500px;

    width: calc(100% - 40px);

    @include respond-to("medium") {

        width: calc(50% - 40px);

        &.big-thumbnail{
            width: calc(100% - 40px);
        }  
    }

    @include respond-to("huge") {

        width: calc(100% / 3 - 40px);

        &.big-thumbnail{
            width: calc(100% / 3 * 2 - 40px);
        }
    }
}

.blog-post-preview-thumbnail-container{
    height: 224px;
    position: relative;

    cursor: pointer;

    @include respond-to("huge") {
        height: 261px;
    }

    width: 100%;
}

.blog-post-preview-image{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-post-preview-thumbnail-mask{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;

    background: linear-gradient(0deg, #231F20, #231F20), linear-gradient(0deg, #D1CBC2, #D1CBC2);
    background-blend-mode: lighten, multiply;
    mix-blend-mode: multiply;
}

.blog-post-preview-thumbnail-skeleton {
    height: 100%;
}

.blog-post-preview-text-wrapper{
    width: 100%;
    // height: 210px;
    // max-height: 210px;
    min-height: 210px;
    flex: 1 1 auto;
    display: flex;
    margin-top: 24px;

    @include respond-to("medium") {
        // height: 165px;
        // max-height: 165px;
        min-height: 165px;
    }

    @include respond-to("huge") {
        // height: 165px;
        // max-height: 165px;
        min-height: 165px;
    }
}

.blog-post-preview-text-left{
    width: 110px;
    // font-family: "Vast Shadow";
    justify-content: center;
}

.blog-post-preview-text-month{
    width: fit-content;
    margin: 0 auto;
    font-size: 32px;
    line-height: 32px;
}

.blog-post-preview-text-date {
    font-size: 56px;
    line-height: 50px;
}

.blog-post-preview-text-right{
    display: flex;
    flex-flow: column;

    margin-left: 18px;
    // font-family: "Myanmar MN";
    max-height: inherit;
    width: 100%;
}

.blog-post-preview-text-title{
    width: 100%;

    // font-family: "Vast Shadow", "MSungHK";
    font-size: 18px;
    line-height: 24px;
}

.blog-post-preview-text-desc-container{
    flex-grow : 1;
    position: relative;
    margin-top: 8px;
    margin-right: 8%;
    font-size: 14px;
    line-height: 22px;
    min-height: 100px;
}

.blog-post-preview-text-desc{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    // font-family: "Myanmar MN", "MSungHK";

    mask-image: -webkit-gradient(linear, left 50%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))
}

.blog-post-preview-hr{
    margin: 8px 0;
    height: 1px;
    width: 100%;
    background-color: color(general, black);
}

.blog-post-preview-tags{
    margin-left: 8px;
    font-size: 14px;
    line-height: 21px;
}

.blog-post-preview-bottom-hr{
    margin: 8px 0;
    height: 4px;
    width: 100%;
    background-color: color(general, black);
}
