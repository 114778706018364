@use '../../styles/abstracts' as *;

.media-page {
    padding-top: 140px;
    width: 100%;
    @include minScreenHeight;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;

    @include respond-to("huge") {
        padding-top: 170px;
    }
}

.media-page-list-wrapper{

    width: 90%;
    margin: 0 auto;

    @include respond-to("huge") {
        width: 80%;
    }
}