@use 'styles/abstracts' as *;

.homesection2 {
    z-index: 0;
    position: relative;
    overflow: hidden;
    padding: 64px 35px;
    // background-color: color(general, black);
    // background-image: url('/assets/images/100cabinet.jpg');
    // @include coverbackground;

    .title {
        color: color('darkgold', 300);
        font-size: 1.5em;
        line-height: 1.5em;
        @include respond-to("large") {
            font-size: 32px;
            line-height: 64px;
        }
    }

    .subtitle {
        white-space: pre-wrap;
        max-width: 90%;
        text-align: center;
        color: color('darkgold', 300);
        text-shadow: 0px 2px 1px #1A100B, 0px 2px 1px #1C0D08, 0px 4px 4px #130C08;
        font-size: 15px;
        line-height: 23px;
        padding-bottom: 2vh;
        
        @include respond-to("large") {
            max-width: 60%;
            font-size: 17px;
            line-height: 26px;
        }
    }

    .slogan {
        white-space: pre-line;
        text-align: center;
        font-size: 1.5em;
        line-height: 1.5em;
        @include respond-to("large") {
            font-size: 32px;
            line-height: 48px;
        }
    }

    .scrolldown {
        display: block;
        position: absolute;
        width: 30%;
        max-width: 100px;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translateX(-60%);
    }

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        z-index: 2;
        text-align: center;
        @include respond-to("large") {
            gap: 18px;
        }
    }

    .deco-star {
        width: 30px;
        height: 36px;
        transform: scale(0.6);
        fill: color('darkgold', 300);

        @include respond-to("large") {
            transform: scale(0.8);
        }
    }
}