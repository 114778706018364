html {
    font-size: 16px;
}
html.scroll-behavior-auto {
    scroll-behavior: auto !important;
}
html.scroll-behavior-smooth {
    scroll-behavior: smooth !important;
}
body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}
a {
    text-decoration: none;
    color: inherit;
}
