$breakpoints: (
  'medium': (min-width: 800px),
  'large': (min-width: 1000px),
  'huge': (min-width: 1200px),
);

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
      $query: if(
        type-of($raw-query) == 'string',
        unquote($raw-query),
        inspect($raw-query)
      );

      @media #{$query} {
        @content;
      }
    } @else {
      @error 'No value found for `#{$breakpoint}`. '
           + 'Please make sure it is defined in `$breakpoints` map.';
    }
  }
