@use '../../styles/abstracts' as *;

.article-list-view-title{
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
}

.article-list-entry-divider{
    width: 100%;
    height: 3px;
    border-top: solid 0.8px color(general, light-black);
    border-bottom: solid 0.8px color(general, light-black);
}

.article-list-view-pagination-wrapper{
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 20px;

    height: 48px;
    width: 100%;
    font-family: "Vast Shadow";
    display: flex;
    align-items: center;
    justify-content: center;
}

.article-list-view-pagination-previous-button{
    cursor: pointer;
    transform: scaleX(-1);
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.article-list-view-pagination-next-button{
    cursor: pointer;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.article-list-view-pagination-page-number-button{
    font-size: 20px;
    line-height: 28px;
    width: 20px;
    margin: 0px 8px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.clickable{
        cursor: pointer;
    }

    &.current-page{
        border-bottom: solid 2px color(general, black);
        margin-top: 2px;
    }
}