@use 'styles/abstracts' as *;

.disclaimer-layout {
    width: 100vw;
    height: 100vh;
    padding: 20px 0;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    background: linear-gradient(0deg, rgba(27, 18, 9, 0.95), rgba(27, 18, 9, 0.95)), radial-gradient(46.68% 46.68% at 50% 47.41%, rgba(64, 59, 44, 0) 35.94%, #403B2C 100%), url('/assets/images/100cabinet-origin.jpg');
    background-blend-mode: multiply, multiply, normal;
    @include coverbackground;
    color: rgba($color: #fff, $alpha: 1.0);
}

.disclaimer {
    
    width: 100%;
    flex: 1;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        margin: 0 35px;
        gap: 32px;
        text-align: center;
        padding-bottom: 10px;
    }

    .logo {
        width: 250px;
        max-width: 100%;
        padding: 0 5vh 0 5vh;

        @include respond-to("large") {
            max-width: 18%;
            padding: 0;
        }
    }

    .passages {
        max-width: 1060px;
        text-align: center;
        color: color(general, white100);
        opacity: 0.8;
        font-size: 16px;

        p + p {
            margin-top: 1em;
        }
    }
}