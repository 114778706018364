@use 'styles/abstracts' as *;

.homesection1 {
    position: relative;
    overflow: hidden;
    padding: 64px 35px;

    .logo {
        position: relative;
        display: block;
        margin: 0 auto;
        // top: 64px;
        min-width: 30vw;
        width: 450px;
        max-width: 55%;

        img {
            width: 80%;
            margin: auto;
        }
    }

    .subtitle {
        max-width: 620px;
        text-align: center;
        margin-bottom: 12px;
        font-size: 16px;

        @include respond-to("large") {
            font-size: 20px;
        }

        &__darkgold {
            color: color(darkgold, 500);
            text-align: center;
            padding-top: 20px;
        }
    }

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 2;
        gap: 12px;
        padding-bottom: 10vh;
    }
}