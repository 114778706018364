@use 'styles/abstracts' as *;

.magnolia-section-1 {
    position: relative;
    width: 100%;
    @include minScreenHeight;
    z-index: 0;

    background: linear-gradient(0deg, #E9DBB9, #E9DBB9), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;
    @include repeatbackground;

    .content-container {
        display: block;
        width: 100%;
        @include minScreenHeight;
        overflow: hidden;
    }

    h5 {
        padding-bottom: 24px;
    }

    h6 {
        padding: 16px;
    }
    
    .font-xl{
        padding-bottom: 16px;
    }

    .deco-star{
        transform: scale(0.4);
    }

    .sticky-bottle {
        position: sticky;
        position: -webkit-sticky;
        @include flexcenter;
        width: 1400px;
        max-width: 100%;
        margin: 0 auto;
        overflow: hidden;

        z-index: 0;

        height: 100vh;
        top: 0;
        padding: 140px 20px 80px;

        &__bottle-container {
            position: relative;
            max-width: 100%;
            max-height: 100%;
            width: 600px;
            height: 100%;
            @include flexcenter;
            pointer-events: none;
        }
        &__bottle {
            // min-width: 40%;
            max-width: 100%;
            max-height: 100%;
            transition: filter 0.3s;
            position: absolute;

            &.shadow {
                filter: drop-shadow(0 0 15px rgba(0,0,0, 0.5));
            }
        }

        &__lable {
            position: absolute;
            transform: translate(0, -50%);
            color: color(general, white100);
            text-transform: uppercase;
            max-width: 90%;
        }

        &__content {
            @include sameSizeWithParent;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap:wrap;

            @include respond-to("large") {
                justify-content: space-between;
                align-items: flex-start;
                align-content: center;
            }
        }

        &__description {
            // position: absolute;
            margin: 20px;
            text-align: center;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 80%;

            &:not(:first-of-type) {
                position: absolute;
                @include respond-to("large") {
                    position: relative;
                }
            }

            @include respond-to("large") {
                position: relative;
                width: 26%;
                margin: 60px;
            }
        }

        &__ingredient-container {
            @include sameSizeWithParent;

            // background: url('/assets/images/product/bkg-wood.jpg');
            // @include coverbackground;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 90px;
            z-index: -1;
        }
        &__ingredient-background {
            @include sameSizeWithParent;
            z-index: -1;
            // overflow: hidden;
        }
        &__table {
            position: sticky;
            width: 100%;
            height: 100vh;
            left: 0;
            top: 0%;
            background: url('/assets/images/product/bkg-wood.jpg');
            @include coverbackground;
        }
        &__ingredients {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 30px;
            padding: 30px 0;
            width: 100%;
            height: 100%;
        }
        &__ingredients-column {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            gap: 30px;
            height: 100%;
            min-width: 40%;
        }
        &__ingredients-note {
            position: relative;
            width: 100%;
            text-align: center;
            color: color(darkgold, 100);
            padding: 0 2em 28px;
            display: none;
            font-size: 0.7em;
            line-height: 1.3em;
            @include respond-to("large") {
                display: block;
            }
        }
        &__ingredient {
            @include flexcenter;
            flex-direction: column;
            text-align: center;
            width: 100%;
            color: color(general, white100);
            cursor: pointer;
            
            > img {
                width: 90%;
                @include respond-to("large") {
                    width: 50%;
                }
            }

            figcaption {
                z-index: 0;
                position: relative;
                color: color(general, white100);
                padding: .25em 1em;
                line-height: 1;

                &::after {
                    // content: '';
                    z-index: -1;
                    @include sameSizeWithParent;
                    background-color: color(sand, 75);
                    transform: rotate(-1deg);

                    box-shadow: -4px 8px 10px 4px rgba(33, 34, 36, 0.5);
                }
            }
        }
    }

}