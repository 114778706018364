@use 'styles/abstracts' as *;

.dotted-line {
    overflow: hidden;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    display: flex;

    &__mobile {
        @include respond-to("large") {
            display: none;
        }
    }
    &__desktop {
        display: none;
        @include respond-to("large") {
            display: block;
        }
    }

    svg {
        flex-shrink: 0;
    }
    svg line {
        stroke: currentColor;
    }
}