@use 'styles/abstracts' as *;

$background: rgba(223, 202, 178, 1);

.promotion-used {
    width: 100%;
    position: fixed;
    min-height: 100vh;
    background: $background;
    color: color(general, black);
    top: 0;
    left: 0;
    gap: 1em;
    z-index: 1000;

    a {
        color: color(darkgold, 500);
    }
}