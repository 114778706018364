@use 'styles/abstracts' as *;

.founder-james-section {
    position: relative;
    @include flexcenter;
    flex-direction: column;

    background-color: rgba(140, 62, 56, 1);
    color: rgba(221, 162, 150, 1);

    .fullpage__page {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
        height: auto;
        min-height: auto;
        padding-top: 120px;
        
        @include respond-to("large") {
            padding: 120px 0;
        }
    }

    &__description,
    &__history {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 16px;
        width: 760px;
        max-width: 100%;
        // padding: 0 20px;

        @include respond-to("large") {
            margin: 0 30px 0 auto;
            padding: 0 35px;
        }
        
        .deco-star {
            flex: 1 0 auto;
            width: 30px;
            height: 36px;
            transform: scale(0.7);

            @include respond-to("large") {
                transform: scale(1);
            }
        }
    }
    &__description {
        padding: 40% 10% 0 10%;

        @include respond-to("large") {
            padding: 0 20px 0 40px;
        }
    }

    &__history {
        padding: 0 10% 10% 10%;

        @include respond-to("large") {
            padding: 0 20px 0 40px;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        // gap: 2em;

        .p {
            margin: 0 0 2em 0;
            @include respond-to("large") {
                margin: 0 12% 1em 0;
            }
        }

        h4{
            padding-right: 11%;
            font-weight: 500;
        }
    }


    &__background {
        @include sameSizeWithParent;
    }

    .sticky-founder-image {
        position: sticky;
        top: 15%;
        left: 0%;
        width: 880px;
        max-width: 90%;
        overflow: hidden;
    }

    &__title {
        position: relative;

        @include respond-to("large") {
            width: 80%;
            margin-left: 0;
        }

        span br {
            display: none;
            @include respond-to("large") {
                display: inline;
            }
        }

        span{
            margin-right: 2%;
        }
    }

    .caption{
        font-size: 16px;
        line-height: 23px;
        margin: 0 10% 1em 0;
    }

    .line-decoration {
        position: absolute;
        top: 1.5em;
        right: 0;
        height: 1px;
        background-color: rgba(221, 162, 150, 1);
        width: 100vw;
        display: flex;
        justify-content: center;

        &--right {
            left: -1em;
        }

        img {
            position: absolute;
        }
        
        &--left img {
            right: -1em;
            top: -1.5em;
            width: 2em;
        }
        &--right img {
            left: -4em;
            top: -1em;
            width: 4em;
        }
    }
}