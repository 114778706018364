@use '../abstracts' as *;
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@1,400;1,500&display=swap');

@font-face {
    font-family: 'Myanmar MN';
    src: local('assets/MyanmarMN.ttf'), url(./../../assets/fonts/MyanmarMN.ttf) format('truetype');
}

@font-face {
    font-family: 'Didot';
    src: local('assets/AWConquerorDidot.otf'), url(./../../assets/fonts/AWConquerorDidot.otf) format('opentype');
}

@font-face {
    font-family: "MSungHK";
    src: local("assets/MSungHK-Medium.otf"), url(./../../assets/fonts/MSungHK-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Bodoni 72';
    src: local('assets/bodoni_72.ttf'), url(./../../assets/fonts/bodoni_72.ttf) format('truetype');
}

@font-face {
    font-family: 'Cormorant';
    src: local('assets/Cormorant'), url(./../../assets/fonts/CormorantInfant-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Cormorant';
    src: local('assets/Cormorant'), url(./../../assets/fonts/CORMORANT-SEMIBOLD.OTF) format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'Cormorant';
    src: local('assets/Cormorant'), url(./../../assets/fonts/Cormorant-MediumItalic.otf) format('opentype');
    font-style: italic;
}

@font-face {
    font-family: 'Cormorant';
    src: local('assets/Cormorant'), url(./../../assets/fonts/Cormorant-SemiBoldItalic.otf) format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Copperplate';
    src: local('assets/Copperplate'), url(./../../assets/fonts/COPPERPLATE-LIGHT-02.TTF) format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'Copperplate';
    src: local('assets/Copperplate'), url(./../../assets/fonts/Copperplate-01.ttf) format('truetype');
}

@font-face {
    font-family: 'Copperplate';
    src: local('assets/Copperplate'), url(./../../assets/fonts/Copperplate-Bold-03.ttf) format('truetype');
    font-weight: bold;
}

.title-xxl,
h1 {
    font-family: "Cormorant", "Times Sans Serif", "MSungHK", sans-serif;
    font-weight: 300;
    font-size: 64px;
    line-height: 80px;

    @include respond-to("large") {
        font-size: 128px;
        line-height: 128px;
    }
}
.title-xl,
h2 {
    font-family: "Cormorant", "Times Sans Serif", "MSungHK", sans-serif;
    font-weight: 300;
    font-size: 52px;
    line-height: 64px;

    @include respond-to("large") {
        font-size: 72px;
        line-height: 80px;
    }
}
.title-l,
h3 {
    font-family: "Cormorant", "Times Sans Serif", "MSungHK", sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 44px;

    @include respond-to("large") {
        font-size: 35px;
        line-height: 45px;
    }
}
.title-m,
h4 {
    font-family: "Myanmar MN", "Times Sans Serif", "MSungHK", sans-serif;
    font-weight: 250;
    font-size: 24px;
    line-height: 34px;
    @include respond-to("large") {
        font-size: 30px;
        line-height: 43px;
    }
}
.title-s,
h5 {
    font-family: "Myanmar MN", "Times Sans Serif", "MSungHK", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    @include respond-to("large") {
        font-size: 24px;
        line-height: 32px;
    }
}
.title-xs,
h6 {
    font-family: "Myanmar MN", "Times Sans Serif", "MSungHK", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    @include respond-to("large") {
        font-size: 20px;
        line-height: 28px;
    }
}
.title-myanmar { font-family: "Myanmar MN", "Times Sans Serif", "MSungHK", sans-serif; }
.title-shadow { font-family: "Vast Shadow", "Times Sans Serif", "MSungHK", sans-serif; }
.title-cormorant { font-family: "Cormorant", "Times Sans Serif", "MSungHK", sans-serif; }
.title-bodoni { font-family: "Bodoni 72", "Times Sans Serif", "MSungHK", sans-serif;}
.title-bodoniModa { font-family: 'Bodoni Moda', "Times Sans Serif", "MSungHK", sans-serif; }
.title-copperplate { font-family: 'Copperplate', "Times Sans Serif", "MSungHK", sans-serif; }
.italic { font-style: italic;}
.light { font-weight: lighter;}
.bold { font-weight: bold;}
.pre-line {white-space: pre-line;}

.locale-en {
    .title-bodoniModa {
        font-style: italic;
    }
}
.locale-zh {
    
}

*::before,
*::after,
body {
    font-family: "Myanmar MN", "Times Sans Serif", "MSungHK", sans-serif;
    font-weight: 250;
    font-size: 17px;
    line-height: 26px;
}
.font-xl {
    font-size: 20px;
    line-height: 26px;
}
.font-l {
    font-size: 17px;
    line-height: 26px;
}
.font-m {
    font-size: 15px;
    line-height: 23px;
}
.font-s {
    font-size: 14px;
    line-height: 18px;
}
.font-xs {
    font-size: 13px;
    line-height: 18px;
}
.bold {
    font-weight: bold;
}
.light {
    font-weight: lighter;
}

.font-shadow--1 {
    text-shadow: 0px 2px 4px rgba(24, 39, 75, 0.12), 0px 4px 4px rgba(24, 39, 75, 0.08);
}
.font-shadow--2 {
    text-shadow: 0px 4px 6px rgba(24, 39, 75, 0.12), 0px 8px 8px rgba(24, 39, 75, 0.08);
}
.font-shadow--3 {
    text-shadow: 0px 6px 8px rgba(24, 39, 75, 0.12), 0px 8px 16px rgba(24, 39, 75, 0.08);
}
.font-shadow--4 {
    text-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12), 0px 8px 24px rgba(24, 39, 75, 0.08);
}
.font-shadow--5 {
    text-shadow: 0px 6px 14px rgba(24, 39, 75, 0.12), 0px 10px 32px rgba(24, 39, 75, 0.1);
}
.font-shadow--6 {
    text-shadow: 0px 8px 18px rgba(24, 39, 75, 0.12), 0px 12px 42px rgba(24, 39, 75, 0.12);
}
.font-shadow--7 {
    text-shadow: 0px 8px 22px rgba(24, 39, 75, 0.12), 0px 14px 64px rgba(24, 39, 75, 0.12);
}
.font-shadow--8 {
    text-shadow: 0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14);
}
