@use '../../styles/abstracts' as *;

.article-list-entry-wrapper{
    width: 100%;

    @include respond-to("huge") {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 61px;
    }
}

.article-list-entry-left-sub-wrapper{
    width: 100%;
    
    @include respond-to("huge") {
        margin-left: 16px;
        display: flex;
        justify-content: start;
        max-height: 26px;
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 1.0) 90%, transparent);
        width: calc(100% - 175px);
    }

    &.no-right-type{
        @include respond-to("huge") {
            width: calc(100%);
        }
    }
}

.article-list-entry-date{
    font-family: "Myanmar MN";
    font-size: 16px;
    line-height: 22px;
    width: 160px;
    margin-bottom: 8px;
    margin-top: 16px;
    display: flex;
    align-items: center;

    @include respond-to("huge") {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.article-list-entry-title{
    font-family: "Myanmar MN", "Noto Sans TC";
    font-size: 18px;
    line-height: 26px;
    
    max-height: 100%;
    font-weight: 500;
    width: 90%;
    margin-bottom: 8px;

    @include respond-to("huge") {
        width: unset;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
        margin-bottom: 0;
    }
}

.article-list-entry-article-type{
    font-family: "Myanmar MN";
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;

    @include respond-to("huge") {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0px;
    }
}