@use 'styles/abstracts' as *;

.ingredient-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, color(darkgold, 500), color(darkgold, 500)), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;

    overflow: auto;

    &__container {
        width: 1000px;
        max-width: 100%;
        padding: 30px;

        gap: 1em;

        flex-direction: column;
        @include respond-to("large") {
            flex-direction: row;
        }
        figure {
            padding: 1em;
        }
    }
    &__content {
        gap: 0.5em;
    }

    figure {
        flex-shrink: 0;
        width: 400px;
        max-width: 100%;

        > img {
            max-height: 30vh;
            margin: 0 auto;
        }
    }

    .close {
        position: absolute;
        top: 60px;
        left: 0;
        margin: 0 16px;
        cursor: pointer;

        > * {
            vertical-align: middle;
        }

        &__icon {
            margin: 0 1em;
        }
        &__text {
            display: none;
            @include respond-to("large") {
                display: inline;
            }
        }

        @include respond-to("large") {
            left: auto;
            right: 0;
            margin: 0 56px;
        }
    }
}
