@use 'styles/abstracts' as *;

.privacy-policy-page {
    width: 100%;
    @include minScreenHeight;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;

    &__wrapper {
        width: 1400px;
        max-width: 100%;
        margin: 0 auto;
        padding: 120px 2em 60px;
        font-size: 13px;
        line-height: 1.5em;
    }

    h1 {
        font-size: 2em;
        line-height: 1.25em;
        margin: 1em 0 .25em;
    }
    h2 {
        font-size: 1.5em;
        line-height: 1.25em;
        margin: 1em 0 .25em;
    }
}