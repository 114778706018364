@use 'styles/abstracts' as *;

.blog-page{
    position: relative;
    padding-top: 100px;
    width: 100%;
    @include minScreenHeight;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;

    @include respond-to("huge") {
        padding-top: 160px;
    }

    .coming-soon {
        position: relative;
        height: 600px;
        @include flexcenter;
        text-align: center;
    }
}

.blog-group{
    width: 95%;
    // @include minScreenHeight;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    @include respond-to("huge") {
        width: 80%;
    }
}
