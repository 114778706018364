@use 'styles/abstracts' as *;

.promotion-popup {
    position: relative;
    z-index: 1000;
    
    &__background {
        z-index: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.787);
    }
    &__wrapper {
        z-index: 1;
        position: fixed;
        background: color(darkgold, 500);
        width: 956px;
        max-width: 90%;
        height: 500px;
        max-height: 80%;
        padding: 120px 20px 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        @include respond-to("huge") {
            height: 600px;
        }
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &__stamps {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 10px;
            top: 10px;
            
            .stamp:first-child {
                width: 140px;
                @include respond-to("large") {
                    width: 250px;
                }
            }
            .stamp:not(:first-child) {
                position: absolute;
                right: 75%;
            }
        }
        
        &__close {
            position: absolute;
            right: 0;
            top: -2em;
            cursor: pointer;
            color: color(grey, 500);
            
            > * {
                vertical-align: middle;
            }
            
            svg path {
                fill: currentColor;
            }
        }
        
        .subtitle {
            text-align: center;
            font-size: 20px;
            line-height: 18px;
            padding-bottom: 8px;
            
            @include respond-to("large") {
                font-size: 22px;
                padding-bottom: 18px;
            }
        }
        
        .title {
            text-align: center;
            font-size: 26px;
            line-height: 1;
            
            @include respond-to("large") {
                font-size: 40px;
            }
        }
        
        .ready {
            position: relative;
            text-align: center;
            color: color(general, white100);
            margin: 20px auto;
            padding: 0 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            line-height: 1.2em;
            
            > * {
                vertical-align: middle;
                padding: 0 0.25em;
            }
            
            svg {
                position: absolute;

                &:first-child {
                    left: 0;
                }
                &:last-child {
                    right: 0;
                }
            }
            svg path {
                fill: currentColor;
            }
        }
    }
    &__hand {
        z-index: -1;
        position: fixed;
        top: 25%;
        right: -20px;
        width: 250px;
        max-width: 25%;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        @include respond-to("large") {
            right: -40px;
        }

        > img {
            display: block;
            transform-origin: right;

            animation: bounce 2.5s infinite 1s;
        }
        
        @keyframes bounce {
            0% {
              transform:  rotate(0deg) ;
            }
            11% {
              transform:  rotate(13deg) ;
            }
            11% {
              transform:  rotate(13deg) ;
            }
            22% {
              transform:  rotate(-15deg) ;
            }
            31% {
              transform:  rotate(10deg) ;
            }
            40% {
              transform:  rotate(-10deg) ;
            }
            50% {
              transform:  rotate(0deg) ;
            }
            100% {
              transform:  rotate(0deg) ;
            }
          }
    }
}