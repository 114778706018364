@use 'styles/abstracts' as *;

.product-detail-journey-parent {
    background-color: color(general, black);
    width: 100%;
}

.product-detail-journey-sticky-wrapper{
    overflow-x: hidden;
    background-color: color(general, light-black);
    height: 100vh;
    width: 100%;
    top: 0;
    position: sticky;
}

.product-detail-journey-relative-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
}

.product-detail-journey-container{
    display: flex;
    position: absolute;
}

.product-detail-journey-scroll-section{
    height: 100vh;
}

.product-detail-journey-section{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

.product-detail-journey-section-content-wrapper{
    width: 100%;
    height: 100%;
    z-index: 0;
}

.product-detail-journey-section-content{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    z-index: 2;
    transform: scale(0.6);

    @include respond-to("huge") {
        transform: scale(1);
    }

    > .product-detail-journey-section-sandalwood-image{
        margin-bottom: 9px;

        @include respond-to("huge") {
            margin-bottom: 18px;
        }
    }

    > .product-detail-journey-section-berry-image{
        margin-bottom: 14px;

        @include respond-to("huge") {
            margin-bottom: 28px;
        }
    }

    > .product-detail-journey-section-citrus-image{
        margin-bottom: 14px;

        @include respond-to("huge") {
            margin-bottom: 28px;
        }
    }

    > .product-detail-journey-section-leafy-image{
        margin-bottom: 23px;

        @include respond-to("huge") {
            margin-bottom: 46px;
        }
    }

    > .product-detail-journey-section-plum-image{
        margin-bottom: 14px;

        @include respond-to("huge") {
            margin-bottom: 28px;
        }
    }

    > .product-detail-journey-section-floral-image{
        margin-bottom: 14px;
        mix-blend-mode: multiply;

        @include respond-to("huge") {
            margin-bottom: 28px;
        }
    }

    > .product-detail-journey-section-spicy-image{
        margin-bottom: 14px;

        @include respond-to("huge") {
            margin-bottom: 28px;
        }
    }
}

.product-detail-journey-section-content-fade-in {

    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;

    &.small-width{
        width: 80%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    &.background-cover{
        background-size: cover;
    }

    &.top-fade-in{
        background-position: 50% 0%;

        background-size: auto 30%;
        @include respond-to("large") {
            background-size: contain;
        }
    }

    &.bottom-fade-in{
        background-position: 50% 100%;

        background-size: auto 30%;
        @include respond-to("large") {
            background-size: contain;
        }
    }
}

.product-detail-journey-section-title-text{
    font-family: "Cormorant", "MSungHK";
    font-size: 72px;
    line-height: 72px;
    

    @include respond-to("huge") {
        font-size: 80px;
        line-height: 80px;
    }


    &.sandalwood-text{
        color: #FFCD8D;
    }

    &.citrus-text {
        color: #F25321;
    }

    &.berry-text{
        color: #BF2378;
    }

    &.leafy-text{
        color: #35C226;
    }

    &.plum-text{
        color: #BF2378;
    }

    &.floral-text{
        color: #db2064;
    }

    &.spicy-text{
        color: #EF2632;
    }
}

.product-detail-journey-line-container{
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    bottom: 10%;
    left: 0;
}

.bicycle-guy{
    position: absolute;
    z-index: 11;
    bottom: 10%;
    width: 74px;
    height: 101px;
    transform-origin: bottom
}