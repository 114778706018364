@use 'styles/abstracts' as *;

.magnolia-section-3 {
    
    position: relative;
    width: 100%;
    @include minScreenHeight;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;
    @include repeatbackground;

    z-index: 0;

    .enjoy-with {
        position: relative;
        width: 100%;
        @include minScreenHeight;
        @include flexcenter;
        overflow: hidden;
        padding: 200px 20px 120px 20px;


        &__bottle {
            position: relative;
            width: 500px;
            max-width: 100%;

            figure{
                position: relative;
                @include flexcenter;

                img {
                    max-width: 86%;
                }
            }
        }

        &__caption {
            position: absolute;
            text-align: center;
            color: color(general, white100);
            top: 13%;
            line-height: 45px;
            
            @include respond-to("large") {
                top: 25%;
                white-space: nowrap;
            }
        }
    }

}