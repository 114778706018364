@mixin coverbackground {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@mixin repeatbackground {
    background-position: center;
    background-repeat: repeat;
}
@mixin flexcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin sameSizeWithParent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
@mixin minScreenHeight {
    min-height: -webkit-fill-available;
    min-height: 100vh;
}
