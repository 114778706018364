@use 'styles/abstracts' as *;

.footer {
    background: linear-gradient(0deg, #231F20, #231F20), url('/assets/images/noise-bkg.png');
    background-blend-mode: multiply, normal;
    color: color(grey, 500);
    padding: 35px;
    @include respond-to("large") {
        padding: 35px 80px;
    }

    &__container {
        flex-wrap: wrap;
        flex-direction: column;
        
        @include respond-to("large") {
            display: flex;
            height: 320px;
        }
    }

    &__stay-connect {
        flex: 0 0 25%;
        padding-bottom: 1em;

        h6 {
            color: color(general, white100);
            margin: 0.5em 0;
        }
    }
    &__nav {
        
        flex: 0 0 100%;
        order: 1;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 2em;
    }
    &__navItem {
        margin: 0.5em 0;
        font-size: 13px;

        @include respond-to("large") {
            margin: 0.25em 0;
        }
    }

    &__contact {
        flex: 0 0 50%;
        min-width: 50%;
        width: 600px;
        max-width: 100%;
    }
    &__contactItem {
        margin: 0.25em 0;
        font-size: 13px;
    }
    & &__copyright {
        font-size: 12px;
        padding-top: 1em;
        text-align: center;
    }

    hr {
        margin: 0.5em 0;
        border: 0;
        border-top: 0px double color(general, white100);
    }

    .input-field {
        min-width: 50%;
        width: 600px;
        max-width: 100%;
    }
        
    .button {
        &--submit {
            min-width: 60px;
            background: color(general, white100);
        }
    }
}
