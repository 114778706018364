@use '../abstracts' as *;

.button {
    position: relative;
    min-width: 180px;
    text-align: center;
    padding: 0.5em;
    color: color("general", "white100");
    background: none;
    border: 2px transparent solid;
    overflow: hidden;
    cursor: pointer;
	vertical-align: middle;
	z-index: 1;
    transition: color 0.3s;

    @include respond-to("large") {
        outline: 1px color("general", "white100") solid;
    }

    > span {
        vertical-align: top;
        svg {
            vertical-align: top;
        }
    }

    &:disabled {
        color: color(general, white50) !important;
        background: color(general, white50) !important;
        
        &::before {
            background: color(general, white50) !important;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 150%;
        height: 100%;
        background: #37474f;
        z-index: -1;
        -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
        transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
        transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
    }
    &::after {
        content: '';
        @include sameSizeWithParent;
        border: 1px color("general", "white100") solid;
        
        @include respond-to("large") {
            display: none;
        }
    }

    &:hover,
    &:focus {
        color: color(general, black);

        &::before {
            opacity: 1;
            background-color: color("general", "white100");
            -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
            -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
            transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
        }
    }

    &--light {
        color: color("general", black);
        background: color(general, white100);
        border: 2px color("general", black) solid;

        &:hover,
        &:focus {
            color: color(general, white100);

            &::before {
                background-color: color("general", black);
            }
        }
    }

    &--grey {
        color: color(general, white100);
        background: color(general, light-black);
        border: 2px color(general, light-black) solid;

        &:hover,
        &:focus {
            color: color(general, white75);

            &::before {
                background-color: color(general, white100);
            }
        }
    }

    & + & {
        margin-left: .5em;
    }

    &--rounded {
        border-radius: 8px;

        @include respond-to("large") {
            outline: 0;
        }

        &::before,
        &::after {
            display: none;
        }
    }
}
