@use 'styles/abstracts' as *;

.product-detail-journey-prologue-wrapper {
    background-color: color(general, light-black);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-detail-journey-prologue-caption {
    text-align: center;
    width: 75%;
    font-size: 32px;
    line-height: 52px;
    font-family: "Vast Shadow", "MSungHK";
    color: color(general, white100);
    text-transform: uppercase;

    @include respond-to("huge") {
        font-size: 56px;
        line-height: 64px;
    }
}

.product-detail-journey-prologue-pointer {
    margin-top: 40px;
    width: 82.51px;
    height: 46.21px;

    transform: scale(0.75);

    @include respond-to("huge") {
        transform: scale(1);
    }

    > path{
        fill: color(general, white100);
    }
}